import React from 'react';
import PropTypes from 'prop-types';
import PhotoAlbum from "react-photo-album";
import ImageViewer from 'react-simple-image-viewer';
import { LazyLoadImage, LazyLoadComponent  } from 'react-lazy-load-image-component';
import countries from '../data/countries/countryData.json';
import 'react-lazy-load-image-component/src/effects/blur.css';


class Country extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            photos:[],
            photoSources:[],
            mapSrc:false,
            artifact:false,
            isViewerOpen:false,
            currentImage:0,
            country:null,
        }
    }
    
    photoClicked = (e, photo, index) => {
        console.log("phot sources are");
        console.log(this.state.photoSources);

        this.setState({
            isViewerOpen:true,
            photoSources:this.getPhotoSource('photoSrc'),
            currentImage:index,
        })
    }

    mapClicked = () => {
        this.setState({
            isViewerOpen:true,
            photoSources:this.getPhotoSource('mapSrc'),
            index:0
        })
    }

    closeImageViewer = () => {
        this.setState({
            currentImage:0,
            isViewerOpen:false
        });
    }

    getPhotoSource = (srcName) => {
        console.log(srcName);
        let photoSources = [];
        switch(srcName){
            case "mapSrc":
                if (this.state.mapSrc){
                    photoSources = [this.state.mapSrc.replace("s.jpg",".jpg")]
                }
                break;
            case "photoSrc":
                if (this.state.photos){
                    photoSources = this.state.photos.map(photo => photo.src.replace("s.jpg",".jpg"));
                }
                break;
        }

        return photoSources;    

    }

    fetchCountryData = (countryCode) => {
        fetch(`https://api.benmaddox.net/getCountryVisitedData?country=${countryCode}`)
            .then(response => {
                if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json(); // Or response.text() if the API returns plain text
            })
            .then(data => {
                console.log('Data received:', data);
                // Process the received data here
                const countryData = data.Item;
                console.log(countryData);
                if (countryData) {
                    let photoSources = countryData.photos.map(photo => photo.src.replace("s.jpg",".jpg"));
                    this.setState({
                        photos: countryData.photos,
                        mapSrc: countryData.mapSrc,
                        artifact: countryData.artifact,
                        photoSources: photoSources,
                        isViewerOpen: false,
                    })
                } else {
                    console.log('country not found');
                    this.setState({
                        photos: [],
                        mapSrc: false,
                        artifact: false,
                        photoSources: [],
                        isViewerOpen: false,
                    })                    
                }
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                this.setState({
                    photos: [],
                    mapSrc: false,
                    artifact: false,
                    photoSources: [],
                    isViewerOpen: false,
                })    
                // Handle errors here
            });
    }

    /*getImagePath = (countryAbbreviation) => {
        countryAbbreviation = countryAbbreviation.toLowerCase();
        const path = `../images/countries/${countryAbbreviation}/`;
        return path;
    }*/

    componentDidUpdate = (prevProps) => {
        if (this.props.countryData !== prevProps.countryData){
            //For development only, set to false before deploying
            const useLocal = false;
            //TO DO: Ideally I should only pass in the country code not all the geo data
            const countryCode = (this.props.countryData.iso_a3);
            if (!useLocal){
                this.fetchCountryData(countryCode);
            } else {
                let photos = [];
                let photoSources = [];
                let mapSrc = false;
                let artifact = false;
    
                //insert fetch here!
    
                let country = countries.find((country => country.countryCode === this.props.countryData.iso_a3))
                console.log(country);
                if(country){
                    photos = country.photos;
                    mapSrc = country.mapSrc;
                    artifact = country.artifact;
                    //TODO use Get Photo Sources, but you'll have to change the way state is used there
                    photoSources = photos.map(photo => photo.src.replace("s.jpg",".jpg"));
                }
                this.setState({
                    country: country,
                    photos: photos,
                    mapSrc: mapSrc,
                    artifact: artifact,
                    photoSources: photoSources,
                    isViewerOpen: false,
                });
            }
        }
    }

    beforeImageLoad = () => {
        console.log("map is about to start loading");
    }

    render() {
        return(
                <div>
                    {this.props.countryData && 
                        <div>
                            <div className="row mt-3">
                                <div className="text-left">
                                    <h2 className="text-uppercase underline">{this.props.countryData.name}</h2>
                                </div>
                            </div>
                            <div className="row ">
                                {this.state.mapSrc &&
                                    <div className="col-lg-7 col-sm-7 col-xs-12 bg-frame p-1 clickable" onClick={this.mapClicked}>
                                        <LazyLoadImage
                                            className="w-100 bg-frame"
                                            effect="blur"
                                            width={"100%"} 
                                            src={this.state.mapSrc} // use normal <img> attributes as props
                                            beforeLoad={this.beforeImageLoad}
                                            />
                                    </div>
                                }
                                {this.state.artifact &&
                                    <div className="col-lg-5 col-sm-5 col-xs-12 "> 
                                        <LazyLoadImage
                                            width={"100%"} 
                                            effect="blur"
                                            src={this.state.artifact.src}
                                            />   
                                        <h2>{this.state.artifact.name}</h2>
                                        <p>circa {this.state.artifact.year}</p>
                                        <p className="p-2">{this.state.artifact.description}</p>
                                    </div>
                                }
                            </div>
                            <div className="row mt-3">
                                <LazyLoadComponent>
                                    <PhotoAlbum layout="rows" photos={this.state.photos} onClick={this.photoClicked}/>
                                </LazyLoadComponent>
                            </div>
                            <div className="row mt-3">
                            </div>
                            <div className="layer-top">
                                {this.state.isViewerOpen &&
                                    <ImageViewer
                                        src={ this.state.photoSources }
                                        currentIndex={ this.state.currentImage }
                                        disableScroll={ true }
                                        closeOnClickOutside={ true }
                                        onClose={ this.closeImageViewer }
                                        className="layer-top"
                                        />  
                                }
                            </div>
                        </div>
                    }
                </div>
        );
    }
}

Country.propTypes = {
    countryData: PropTypes.object
}

export default Country;